@import "mixin";
@import "variable";

@media screen and (max-width: 1199px) {
  #header {
    #header-cart .block-cart-alter-content-top {
      padding-left: 0;
    }
  }

  #header {
    #navigation {
      .container {
        ul.menu {
          padding-top: 12px;
        }
      }
    }
  }

}

@media screen and (max-width: 990px) {

  body {
    width: 100%;
    overflow-x: hidden;
  }

  #user-register-form .confirm-parent,
  #user-register-form .password-parent {
    width: auto;
  }

  #commerce-checkout-form-review input[type="text"],
  #commerce-checkout-form-review .form-select,
  #commerce-checkout-form-checkout input[type="text"],
  #commerce-checkout-form-checkout .form-select {
    max-width: 80%;
  }

  #commerce-checkout-form-review .commerce_payment #edit-commerce-payment-payment-method label {
    width: 80%;
  }

  #connector-button-form .form-submit {
    padding: 10px 6px;
  }

  #user-register-form .form-item-mail .description {
    max-height: 999em;
  }

  body {
    min-width: 320px;
    background-image: none;
  }

  #navigation {
    display: none;
  }
  .slicknav_menu {
    display: block;
  }
  .slicknav_btn {
    background-color: $gray-darker !important;
    border-radius: 0;
    
    .slicknav_menutxt {
      display: none;
    }

  }

  .js .slicknav_menu {
    background: #000;

    .slicknav_icon {
      margin: 0;
    }

    .slicknav_nav {
      li {
        a {
          &:hover {
            background-color: $gray-dark;
          }

        }
      }
    }
  }

  #top-bar {
    background-color: $gray-dark;
    margin: 0;
    height: auto;

    #top-bar-right {
      @include flex();
    }

    .content > ul.menu li a {
      margin-bottom: 0;
      line-height: 2em;
      font-size: 3vw;
    }

    #block-locale-language a {
      padding: 0 5px;
      line-height: 2em;
      font-size: 3vw;
    }
  }

  #header {
    position: relative;
    height: inherit;
    background: #000000 url(../images/bg_header.png) 0 100% repeat-x;
    padding-bottom: 3em;

    .container {
      height: inherit;

      #logo-wrapper {
        margin: 0 0 10px 0;
        width: 40%;
      }

      #header-search {
        .block form {
          padding-bottom: 10px;
        }
      }
    }

    #header-cart {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  #breadcrumb {
    clear: both;
    display: none;
  }

  // product details
  div.horizontal-tabs ul.horizontal-tabs-list li.selected a {
    margin-right: 0;
  }

  .view-id-all_products_use_search_api {
    img {
      display: block;
      margin: 0 auto;
    }
  }

}


@media screen and (max-width: 600px) {
  #top-bar {
    .content > ul.menu li a {
      font-size: 4vw;
    }

    #block-locale-language a {
      font-size: 4vw;
    }
  }
}

@media screen and (max-width: 450px) {
  #top-bar {
    .content > ul.menu li a {
      font-size: 5vw;
    }

    #block-locale-language a {
      font-size: 5vw;
    }
  }
}
